import React, { FC, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useClassnames } from 'hook/use-classnames';
import { useHistory } from 'react-router';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faDollarSign,
    faShareSquare,
    faTimes,
    faTrash,
    faTrophy,
    faUser,
    faUsers
} from '@fortawesome/free-solid-svg-icons';

import IconChecked from 'component/icon/checked';
import IconArrowLeft from 'component/icon/arrow-full-left';
import Form, { useRegistry } from 'component/form';
import InputForm from 'component/form/input';
import InputEvents from 'component/form/input-events/index-tsx-async';
import InputClubs from 'component/form/input-clubs';
import InputAlbums from 'component/form/input-albums';
import InputYears from 'component/form/input-years';
import Button from 'component/button';
import { IStore } from 'store/reducers/types/reducers';
import { key as keyUser } from 'store/reducers/user/reducer';
import Modal from 'component/modal';
import { INormalizeObject } from 'component/helper/normalize-object';
import Checkbox from 'component/checkbox';
import { IValue } from 'component/form/input-years/types';

import { IProps } from './types';
import style from './index.pcss';
import inputStyle from './input.pcss';

const SidebarActions: FC<IProps> = (props) => {
    const cn = useClassnames(style, props.className, true);
    const { t } = useTranslation();
    const priceRegistry = useRegistry();
    const tournamentsRegistry = useRegistry();
    const clubsRegistry = useRegistry();
    const history = useHistory();

    const [showPrice, setShowPrice] = useState<boolean>(false);
    const [year, setYear] = useState<number>(0);
    const [showTournament, setShowTournament] = useState<boolean>(false);
    const [showAlbums, setShowAlbums] = useState<boolean>(false);
    const [showRemove, setShowRemove] = useState<boolean>(false);
    const [modalMessage, setModalMessage] = useState<boolean>(false);
    const [message, setMessage] = useState<string | null>(null);
    const [selectAll, setSelectAll] = useState<boolean>(false);

    const userPhone = useSelector<IStore, string | null>((store) => store[keyUser].fps_phone_number || null);
    const moderationPhone = useSelector<IStore, string | null>((store) => store[keyUser]?.moderation_user?.fps_phone_number || null);
    const personalPhotoId = useSelector<IStore, number | null | undefined>((store) => store[keyUser].personal_photo?.id);

    const _update = useCallback((data: INormalizeObject): void => {
        props.update && props.update({
            ...data,
            ...(selectAll && { 'all_photo': true })
        });
    }, [props.update, selectAll]);

    const onShowMenu = useCallback(() => {
        setYear(0);
        setShowTournament(false);
        setShowPrice(false);
        setShowAlbums(false);
        setShowRemove(false);
    }, []);

    const onClickCheckbox = (): void => {
        setSelectAll((prev) => !prev);
    };

    const onClickRedirectDashboard = (): void => {
        history.push('/dashboard');

        return;
    };

    const onClickCloseMessage = (): void => {
        setModalMessage(false);
        setMessage(null);
    };

    const priceCheckValidity = () => {
        const fields = priceRegistry.form.getFields();

        if(fields) {
            const price = fields.get('price');

            if (price) {
                if (Number(price.value) < 20) {
                    return {
                        isValid: false,
                        error: 'Минимальная стоимость 20 руб.'
                    };
                }

                return {
                    isValid: true,
                    error: ''
                };
            }
        }
    };

    // Открытие форм
    const onClickPrice = useCallback(() => {
        if (!userPhone || moderationPhone) {
            setModalMessage(true);

            return;
        }

        setShowPrice(true);
    }, []);

    const onClickTournament = useCallback(() => {
        setYear(0);
        setShowTournament(true);
    }, []);

    const onClickAlbums = useCallback(() => {
        setShowAlbums(true);
    }, []);

    const onClickRemove = useCallback(() => {
        setShowRemove(true);
    }, []);

    // Сабмиты форм
    const onSubmitPrice = useCallback((payload) => {
        _update({ price: payload.price });
        onShowMenu();
    }, [props.update, selectAll]);

    const onSubmitTournament = useCallback((payload) => {
        _update({ event_id: payload.tournament?.value });
        onShowMenu();
    }, [props.update, selectAll]);

    const onSubmitClubs = useCallback((payload) => {
        _update({ club_id: payload.club?.value });
        onShowMenu();
    }, [props.update, selectAll]);

    const onSubmitAlbums = useCallback((payload) => {
        _update({album_id: payload.album?.value});
        onShowMenu();
    }, [props.update, selectAll]);

    const onSubmitRemove = useCallback(() => {
        _update({is_deleted: true});
        onShowMenu();
    }, [props.update, selectAll]);

    const onClickSetPriceZero = useCallback(() => {
        _update({ price: 0 });
    }, [props.update, selectAll]);

    // Формы
    const elPriceForm = useMemo(() => {
        return (
            <Form
                registry={priceRegistry.form}
                className={cn('sidebar__form')}
                onSubmit={onSubmitPrice}
            >
                <h1 className={cn('sidebar__form-title')}>
                    <IconArrowLeft onClick={onShowMenu} className={cn('sidebar__form-icon')} />
                    {t('components.sidebar-action.forms.titles.cost')}
                </h1>
                <InputForm
                    registry={priceRegistry.field}
                    name="price"
                    children={t('components.sidebar-action.forms.items.cost')}
                    className={cn(inputStyle)}
                    onlyNumbers={true}
                    required={true}
                    defaultValue={'20'}
                    min={20}
                    checkValidity={priceCheckValidity}
                />
                <p className={cn('sidebar__form-commission')}>{t('components.sidebar-action.messages.commission-info')}</p>
                <Button className={cn('sidebar__form-button')} type="submit">
                    {props.isUploadPhotos ?
                        t('components.sidebar-action.forms.end-submit')
                        : t('components.sidebar-action.forms.submit')}
                </Button>
            </Form>
        );
    }, [props.update, selectAll]);

    const elClubsForm = useMemo(() => {
        return (
            <Form
                registry={clubsRegistry.form}
                className={cn('sidebar__form')}
                onSubmit={onSubmitClubs}
            >
                <h1 className={cn('sidebar__form-title')}>
                    <IconArrowLeft onClick={onShowMenu} className={cn('sidebar__form-icon')} />
                    {t('components.sidebar-action.forms.titles.club')}
                </h1>
                <InputClubs
                    registry={clubsRegistry.field}
                    name="club"
                    required={true}
                    className={inputStyle}
                />
                <Button className={cn('sidebar__form-button')} type="submit">
                    {t('components.sidebar-action.forms.submit')}
                </Button>
            </Form>
        );
    }, [props.update, selectAll]);

    const elAlbumsForm = useMemo(() => {
        return (
            <Form
                registry={clubsRegistry.form}
                className={cn('sidebar__form')}
                onSubmit={onSubmitAlbums}
            >
                <h1 className={cn('sidebar__form-title')}>
                    <IconArrowLeft onClick={onShowMenu} className={cn('sidebar__form-icon')} />
                    {t('components.sidebar-action.forms.titles.album')}
                </h1>
                <InputAlbums
                    registry={clubsRegistry.field}
                    name="album"
                    required={true}
                    className={inputStyle}
                />
                <Button className={cn('sidebar__form-button')} type="submit">
                    {props.isUploadPhotos ?
                        t('components.sidebar-action.forms.end-submit')
                        : t('components.sidebar-action.forms.submit')}
                </Button>
            </Form>
        );
    }, [props.update, selectAll]);

    const elRemoveForm = useMemo(() => {
        return (
            <Form
                registry={clubsRegistry.form}
                className={cn('sidebar__form')}
                onSubmit={onSubmitRemove}
            >
                <h1 className={cn('sidebar__form-title')}>
                    <IconArrowLeft onClick={onShowMenu} className={cn('sidebar__form-icon')} />
                    {t('components.sidebar-action.forms.titles.del-photo')}
                </h1>
                <p>{t('components.sidebar-action.messages.del-photo')}</p>
                <Button className={cn('sidebar__form-button')} type="submit">
                    {props.isUploadPhotos ?
                        t('components.sidebar-action.forms.end-submit')
                        : t('components.sidebar-action.forms.submit')}
                </Button>
            </Form>
        );
    }, [props.update, selectAll]);

    const onChangeYear = (value: IValue): void => {
        if (value) {
            setYear(Number(value?.value));
        }
    };

    const elTournamentForm = useMemo(() => {
        const isDisabledEvents = !Boolean(year);

        return (
            <Form
                registry={tournamentsRegistry.form}
                className={cn('sidebar__form')}
                onSubmit={onSubmitTournament}
            >
                <h1 className={cn('sidebar__form-title')}>
                    <IconArrowLeft onClick={onShowMenu} className={cn('sidebar__form-icon')} />
                    {t('components.sidebar-action.forms.titles.event')}
                </h1>
                <InputYears
                    registry={tournamentsRegistry.field}
                    name="year"
                    clearable={true}
                    children={t('components.sidebar-action.forms.items.year')}
                    direction="column"
                    onChange={onChangeYear}
                />
                <InputEvents
                    registry={tournamentsRegistry.field}
                    name="tournament"
                    required={true}
                    clearable={true}
                    excludeEmpty={false}
                    year={year}
                    children={t('components.sidebar-action.forms.items.event')}
                    direction="column"
                    disabled={isDisabledEvents}
                />
                <Button className={cn('sidebar__form-button')} type="submit" disabled={isDisabledEvents}>
                    {props.isUploadPhotos ?
                        t('components.sidebar-action.forms.end-submit')
                        : t('components.sidebar-action.forms.submit')}
                </Button>
            </Form>
        );
    }, [props.update, year, selectAll]);

    const elContent = useMemo(() => {
        if(!props.checkedCount) {
            return (
                <div className={cn('sidebar__empty')}>
                    <IconChecked className={cn('sidebar__empty-icon')} />
                    <p>{t('components.sidebar-action.messages.select-photo')}</p>
                </div>
            );
        }

        if(props.checkedCount && !showPrice && !showTournament && !showAlbums && !showRemove) {
            return (
                <div className={cn('sidebar__menu')}>
                    <h2 className={cn('sidebar__menu-header')}>{props.checkedCount} фото выбрано</h2>
                    <div onClick={onClickTournament} className={cn('sidebar__menu-item')}>
                        <div className={cn('sidebar__menu-icon')}><FontAwesomeIcon icon={faTrophy} /></div>
                        <div>{t('components.sidebar-action.menu-items.select-event')}</div>
                    </div>
                    <div onClick={onClickAlbums} className={cn('sidebar__menu-item')}>
                        <div className={cn('sidebar__menu-icon')}><FontAwesomeIcon icon={faShareSquare} /></div>
                        <div>{t('components.sidebar-action.menu-items.select-album')}</div>
                    </div>
                    <div onClick={onClickPrice} className={cn('sidebar__menu-item')}>
                        <div className={cn('sidebar__menu-icon')}><FontAwesomeIcon icon={faDollarSign} /></div>
                        <div>{t('components.sidebar-action.menu-items.cost')}</div>
                    </div>
                    <div onClick={onClickSetPriceZero} className={cn('sidebar__menu-item')}>
                        <div className={cn('sidebar__menu-icon')}><FontAwesomeIcon icon={faTimes} /></div>
                        <div>{t('components.sidebar-action.menu-items.remove-sale')}</div>
                    </div>
                    <div onClick={onClickRemove} className={cn('sidebar__menu-item')}>
                        <div className={cn('sidebar__menu-icon')}><FontAwesomeIcon icon={faTrash} /></div>
                        <div>{t('components.sidebar-action.menu-items.delete')}</div>
                    </div>
                    <div className={cn('sidebar__all')}>
                        <Checkbox
                            className={cn('sidebar__checkbox')}
                            onClick={onClickCheckbox}
                            isChecked={selectAll}
                        />
                        <span>Применить ко всем фото</span>
                    </div>
                </div>
            );
        }

        if (showPrice) {
            return elPriceForm;
        }

        if (showTournament) {
            return elTournamentForm;
        }

        if (showAlbums) {
            return elAlbumsForm;
        }

        if (showRemove) {
            return elRemoveForm;
        }

    }, [props.checkedCount, showPrice, showTournament, showAlbums, showRemove, year, selectAll]);

    const elModalMessageSetPhone = useMemo(() => {
        if (modalMessage) {
            return (
                <Modal onClickClose={onClickCloseMessage}>
                    <div className={cn('sidebar__modal-phone')}>
                        <div className={cn('sidebar__phone-content')}>
                            <h1>{t('components.sidebar-action.forms.modal.title')}</h1>
                            <div className={cn('sidebar__phone-text')}>
                                {t('components.sidebar-action.forms.modal.text')}
                            </div>
                        </div>
                        <div className={cn('sidebar__phone-buttons')}>
                            <Button
                                className={cn('sidebar__phone-cancel')}
                                isSecondary={true}
                                onClick={onClickCloseMessage}
                            >
                                {t('components.sidebar-action.forms.modal.buttons.cancel')}
                            </Button>
                            <Button onClick={onClickRedirectDashboard}>
                                {t('components.sidebar-action.forms.modal.buttons.phone')}
                            </Button>
                        </div>
                    </div>
                </Modal>
            );
        }
    }, [modalMessage]);

    const elMessage = useMemo(() => {
        if (message) {
            return (
                <Modal onClickClose={onClickCloseMessage}>
                    {message}
                </Modal>
            );
        }
    }, [message]);

    return (
        <div className={cn('sidebar')}>
            {elMessage}
            {elModalMessageSetPhone}
            {elContent}
        </div>
    );
};

export default SidebarActions;
