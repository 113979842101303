import React, { FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useHistory } from 'react-router-dom';

import { useClassnames } from 'hook/use-classnames';
import UI from 'component/ui';
import LazyLoad from 'component/lazy-load';
import Attachments from 'component/attachments';

import { IProps } from './types';
import style from './index.pcss';
import SuitIcon from 'component/icon/suit';
import ReactTooltip from 'react-tooltip';
import api from 'src/api';
import { Page } from 'src/api/base';
import { Photo, PhotosSearchFilter } from 'src/api/photos/types';

const SearchResult: FC<IProps> = (props) => {
    const cn = useClassnames(style, props.className, true);
    const { t } = useTranslation();
    const history = useHistory();

    const [linkEvent, setLinkEvent] = useState<string>(`${props.link}`);

    const elDate = useMemo(() => {
        if(props.date) {
            return (
                <div className={cn('search-result__info-item')}>
                    <span className={cn('search-result__info-text')}>{t('components.result.date')}</span>
                    <span className={cn('search-result__info-value')}>{props.date}</span>
                </div>
            );
        }
    }, [props.date]);

    const elPlace = useMemo(() => {
        if(props.place) {
            return (
                <div className={cn('search-result__info-item')}>
                    <span className={cn('search-result__info-text')}>{t('components.result.place')}</span>
                    <span className={cn('search-result__info-value')}>{props.place}</span>
                </div>
            );
        }
    }, [props.place]);

    const onClickSuitIcon = () => {
        history.push('/advertisements');
    };

    const elSellSuitCount = useMemo(() => {
        if(props.place) {
            return (
                <div className={cn('search-result__info-item-suit')}>
                    <ReactTooltip
                        id={`sell-suit-tooltip-${props.eventId}`}
                        place="left"
                        effect="solid"
                        border={true}
                        borderColor="#dee1e4"
                        backgroundColor="#fff"
                        className={cn('person-photo__sale-tooltip')}
                    >
                        Костюмов на продаже
                    </ReactTooltip>
                    <SuitIcon
                        className={cn('search-result__info-item-suit-icon')}
                        data-tip={true}
                        data-type="light"
                        data-for={`sell-suit-tooltip-${props.eventId}`}
                        onClick={onClickSuitIcon}
                    />
                    <span className={cn('search-result__info-value-suit')}>+{props.suit_count}</span>
                </div>
            );
        }
    }, [props.place]);

    // const elPersons = useMemo(() => {
    //     if(props.persons_count) {
    //         return (
    //             <div className={cn('search-result__info-item')}>
    //                 <span className={cn('search-result__info-text')}>{t('components.result.persons')}</span>
    //                 <span className={cn('search-result__info-value')}>{props.persons_count}</span>
    //             </div>
    //         );
    //     }
    // }, [props.persons_count]);

    // const elPhotos = useMemo(() => {
    //     if(props.photos_count) {
    //         return (
    //             <div className={cn('search-result__info-item')}>
    //                 <span className={cn('search-result__info-text')}>{t('components.result.photo')}</span>
    //                 <span className={cn('search-result__info-value')}>{props.photos_count}</span>
    //             </div>
    //         );
    //     }
    // }, [props.photos_count]);

    const elInfo = useMemo(() => {
        const suitCount = !!(props.suit_count && props.suit_count > 0);

        return (
            <div className={cn('search-result__info', suitCount && 'search-result__info-suit')}>
                {suitCount && elSellSuitCount}
                {elDate}
                {elPlace}
                {/* {elPhotos} */}
            </div>
        );
    }, [props.date, props.place, JSON.stringify(props.attachments), JSON.stringify(props.persons_count), props.suit_count]);

    const elAttachments = useMemo(() => {
        if(props.attachments) {
            return (
                <LazyLoad>
                    <Attachments
                        limit={4}
                        link={linkEvent}
                        isLink={true}
                        personsIds={props.persons}
                        attachmentsCount={props.photos_count || props.attachments.length}
                        className={cn('search-result__attachments')}
                        query={props.query}
                    >
                        {props.attachments}
                    </Attachments>
                </LazyLoad>
            );
        }
        if(props.photo_attachments) {
            return (
                <LazyLoad>
                    <Attachments
                        limit={4}
                        link={linkEvent}
                        isLink={true}
                        personsIds={props.persons}
                        attachmentsCount={props.photos_count || props.photo_attachments.length}
                        className={cn('search-result__attachments')}
                        query={props.query}
                        isNewFormat={true}
                    >
                        {props.photo_attachments.map((item) => {
                            return {url: item};
                        })}
                    </Attachments>
                </LazyLoad>
            );
        }
    }, [JSON.stringify(props.attachments), JSON.stringify(props.photo_attachments), props.photos_count, props.persons, linkEvent]);

    useEffect(() => {
        let newLink = `${props.link}?`;

        if (props.persons?.length) {
            const person_ids = props.persons.reduce((acc: string, id: number) => (`${acc}&person_ids=${id}`), '');
            newLink += person_ids;
        }

        if (props.query) {
            newLink += `&${props.query}`;
        }

        if (props.file_id) {
            newLink += `&file_id=${props.file_id}`;
        }

        setLinkEvent(newLink);
    }, [props.persons, props.query, props.file_id]);

    return (
        <UI.Box className={cn('search-result')} padding={true}>
            <Link to={linkEvent} className={cn('search-result__header')}>{props.title}</Link>
            {elAttachments}
            {elInfo}
        </UI.Box>
    );
};

export default SearchResult;
