import React, { FC, useMemo, useState, useEffect, useCallback, Fragment, ReactNode, MouseEvent } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import ReactTooltip from 'react-tooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/free-solid-svg-icons';

import cropImage from 'component/helper/crop-image';
import Carousel from 'component/carousel';
import { useClassnames } from 'hook/use-classnames';
import IconDollar from 'component/icon/icon-dollar';
import IconPlus from 'component/icon/plus';
import IconMasks from 'component/icon/icon-masks';
import Status from 'route/upload/status';

import { DataPhotoListItem } from 'component/api/types/api/photo/get-own-photo-list/get/code-200';

import { ISelectedList, INewProps, INewSortedList } from './types';
import style from './index.pcss';
import { Link } from 'react-router-dom';
import { Photo } from 'src/api/photos/types';
import EventCarousel from 'component/new-carousel';

moment.locale('ru');

const sortList = (list: Array<Photo>) => {
    if(list.length) {
        const obj = list.reduce((accumulator: INewSortedList, current) => {
            if(current) {
                // const key = current.created_at ? moment(current.created_at).format('LL') : moment(current.upload_at).format('LL');
                // @TODO баг - список разделяется потому что даты загрузки и даты создания разные
                const key = moment(current.upload_at).format('YYYY-MM-DD');

                if(key) {
                    if(accumulator[key]) {
                        accumulator[key].push(current);
                    } else {
                        accumulator[key] = [{...current}];
                    }
                }
            }

            return accumulator;
        }, {});

        return Object.keys(obj).sort((a, b) => {
            if(moment(a).isAfter(b)) {
                return -1;
            }

            return 1;
        }).reduce((result: INewSortedList, key) => {
            result[key] = obj[key];

            return result;
        }, {});
    }

    return {};
};

const NewPhotoList: FC<INewProps> = (props) => {
    const cn = useClassnames(style, props.className, true);
    const { t } = useTranslation();

    const [sortedList, setSortedList] = useState<INewSortedList>(sortList(props.list));
    const [selectedList, setSelectedList] = useState<ISelectedList>({});
    const [selectGallery, setSelectGallery] = useState<number | null>(null);
    const [selectGalleryKey, setSelectGalleryKey] = useState<string>('');

    useEffect(() => {
        if (props.resetSelected) {
            setSelectedList({});
        }
    }, [props.resetSelected]);

    useEffect(() => {
        setSortedList(sortList(props.list));
    }, [props.list]);

    useEffect(() => {
        if(props.onChangeSelectedList) {
            const ids = Object.values(selectedList).reduce((accumulator: Array<number>, current) => {
                return accumulator.concat([...current]);
            }, []);

            props.onChangeSelectedList(ids);
        }
    }, [props.onChangeSelectedList, JSON.stringify(selectedList)]);

    useEffect(() => {
        if(props.resetSelectedList) {
            if (props.resetSelectedList()) {
                setSelectedList({});
            }
        }
    }, [props.resetSelectedList, JSON.stringify(selectedList)]);

    const onClick = useCallback((id: number, key: string) => () => {
        const newSelectedList = { ...selectedList };

        if(!newSelectedList[key]) {
            newSelectedList[key] = [];
        }

        const index = newSelectedList[key].indexOf(id);

        if(index > -1) {
            newSelectedList[key].splice(index, 1);
        } else {
            newSelectedList[key].push(id);
        }

        setSelectedList(newSelectedList);
    }, [JSON.stringify(selectedList)]);

    const onClickImage = (index: number, key: string) => (e?: MouseEvent): void => {
        e?.preventDefault();
        e?.stopPropagation();

        setSelectGallery(index);
        setSelectGalleryKey(key);
    };

    const onClickClose = (e?: MouseEvent): void => {
        e?.preventDefault();

        setSelectGallery(null);
        setSelectGalleryKey('');
    };

    const onClickCheckAll = useCallback((key: string) => (): void => {
        const newSelectedList = { ...selectedList };

        if(!newSelectedList[key]) {
            newSelectedList[key] = [];
        }

        const isSelectedEvery = (sortedList[key].length === newSelectedList[key].length) && newSelectedList[key]?.every((item) => item);

        newSelectedList[key] = isSelectedEvery ? [] : sortedList[key].map((item) => item.id);

        setSelectedList(newSelectedList);
    }, [JSON.stringify(sortedList), JSON.stringify(selectedList)]);

    const elBlockIcons = (item: Photo) => {
        if(props.elIcons) {
            return (
                <div className={cn('photo-list__block-icons')}>
                    {/* {item.moderation && <FontAwesomeIcon data-tip="Ожидает модерации" className={cn('photo-list__block-icon')} icon={faEdit} />} */}
                    {!!item.price && <IconDollar tooltip={item.price} className={cn('photo-list__block-icon')} />}
                    {item.event && <IconMasks tooltip={item.event.name} className={cn('photo-list__block-icon')} />}
                    {/* {item.club && <IconPlus tooltip={item.club.name} className={cn('photo-list__block-icon')} />} */}
                </div>
            );
        }
    };

    const elCheckbox = (isChecked: boolean) => {
        if(props.elControls) {
            return (
                <div
                    className={cn('photo-list__checkbox-absolute')}
                >
                    <div
                        className={cn('photo-list__checkbox-inner', {
                            'photo-list__checkbox-inner_checked': isChecked
                        })}
                    />
                </div>
            );
        }
    };

    const elImage = (item: Photo, src: string, key: string, index: number) => {
        const param = {
            src,
            className: cn('photo-list__container'),
            alt: t('components.form.input-file.image.alt', {
                number: index + 1
            })
        };

        if (props.elPortal) {
            return <img {...param} onClick={onClickImage(index, key)} alt={`Image ${item.id}`} />;
        }

        let photo_link;

        if (props.link) {
            photo_link = `${props.link}/photos/${item.id}`;
        } else {
            const event = item.event
                ? item.event.id
                : 0;
            photo_link = `/events/${event}/photos/${item.id}`;
        }

        if (props.query) {
            photo_link += props.query;
        }

        return (
            <Link to={photo_link} target="_blank">
                <img {...param} alt={`Image ${item.id}`} />
            </Link>
        );
    };

    const elImageList = (list: Array<Photo>, key: string) => {
        return (
            <div className={cn('photo-list__list')}>
                {list.map((item, index) => {
                    const isChecked = selectedList[key]?.indexOf(item.id) > -1;
                    const urlUsable = item.photo_url;

                    return (
                        <div
                            key={index}
                            className={cn('photo-list__list-item')}
                            onClick={onClick(item.id, key)}
                        >
                            <div className={cn('photo-list__block')}>
                                {elCheckbox(isChecked)}
                                {elBlockIcons(item)}
                                {elImage(
                                    item,
                                    item.photo_url,
                                    key,
                                    index
                                )}
                            </div>
                        </div>
                    );
                })}
            </div>
        );
    };

    const elPortal = (key: string): ReactNode => {
        if(selectGallery !== null && key === selectGalleryKey) {
            return (
                <EventCarousel
                    photoOwner={props.photoOwner}
                    disableButtons={props.disableButtons}
                    onClose={onClickClose}
                    selected={selectGallery}
                    isModal={true}
                >
                    {sortedList[key]}
                </EventCarousel>
            );
        }
    };

    const elSortedLists = useMemo(() => {
        if(Object.keys(sortedList).length) {
            const keys = Object.keys(sortedList);

            return keys.map((key, index) => {
                const isChecked = selectedList[key]?.length === sortedList[key]?.length && selectedList[key]?.every((item) => item);

                return (
                    <div className={cn('photo-list__sorted-list')} key={index}>
                        <div className={cn('photo-list__checked')}>
                            {props.elControls && (
                                <div
                                    onClick={onClickCheckAll(key)}
                                    className={cn('photo-list__checkbox', {
                                        'photo-list__checkbox_checked': isChecked
                                    })}
                                />
                            )}
                            {props.dateLink && (
                                <a
                                    href={`/dashboard/photos/${moment(key).format('YYYY-MM-DD')}`}
                                    className={cn('photo-list__sorted-date', {
                                        'photo-list__sorted-date_no-control': !props.elControls
                                    })}
                                >
                                    {moment(key).format('LL')}
                                </a>
                            )}
                            {!props.dateLink && (
                                <span
                                    className={cn('photo-list__sorted-date', {
                                        'photo-list__sorted-date_no-control': !props.elControls
                                    })}
                                >
                                    {moment(key).format('LL')}
                                </span>
                            )}
                        </div>
                        {elImageList(sortedList[key], key)}
                        {elPortal(key)}
                    </div>
                );
            });
        }

        if(props.loadingList && props.loadingList.length) {
            return (
                <div className={cn('photo-list__list')}>
                    {props.loadingList.map((item, index) => {
                        const keys = props.errors && Object.keys(props.errors);
                        const isError = keys?.includes(String(index));

                        return (
                            <Fragment key={index}>
                                {props.errors && props.errors[index] && (
                                    <ReactTooltip
                                        className={cn('photo-list__tooltip')}
                                        multiline={true}
                                        effect="solid"
                                    />
                                )}
                                <div className={cn('photo-list__list-item', 'photo-list__list-item_loading')}>
                                    <span className={cn('photo-list__file-name')}>{item.name}</span>
                                    <Status
                                        progress={item.status}
                                        isError={isError}
                                        isDone={!isError}
                                        tooltip={props.errors && props.errors[index]}
                                        className={cn('photo-list__status')}
                                    />
                                </div>
                            </Fragment>
                        );
                    })}
                </div>
            );
        }
    }, [JSON.stringify(sortedList), JSON.stringify(selectedList), props.elControls, JSON.stringify(props.loadingList), JSON.stringify(props.errors), selectGallery, selectGalleryKey]);

    return (
        <div className={cn('photo-list')}>
            {elSortedLists}
        </div>
    );
};

NewPhotoList.defaultProps = {
    elIcons   : true,
    elControls: true,
    elPortal  : true
};

export default NewPhotoList;
