import { AxiosResponse } from 'axios';
import { Page, PaginationResponse, PhotoPaginationResponse } from '../base';
import httpClient from 'src/utils/http-сlient';
import { BulkUpdatePhoto, BulkUpdatePhotoData, CreatePhotoData, CreatePhotoItem, PartialUpdatePhotoData, Photo, PhotoItem, PhotosAPIType, PhotosSearchFilter, UpdatePhotoData, UpdatePhotoItem } from './types';
import queryString from 'query-string';

const getPhotosList = (page?: Page, filter?: PhotosSearchFilter): Promise<AxiosResponse<PhotoPaginationResponse<Photo>>> => {
    return httpClient.get<PhotoPaginationResponse<Photo>>(`/api/v2/photos/`, {
        params: {
            ...({ page: page?.pageNumber && page.pageNumber }),
            ...({ page_size: page?.pageSize ? page.pageSize : 40 }),
            ...filter
        },
        paramsSerializer(params) {
            return queryString.stringify(params, {arrayFormat: 'none'});
        }
    });
};

const createPhoto = (data: CreatePhotoData): Promise<AxiosResponse<CreatePhotoItem>> => {
    return httpClient.post<CreatePhotoItem>('/api/v2/photos/', data);
};

const getPhotoItem = (id: number): Promise<AxiosResponse<PhotoItem>> => {
    return httpClient.get<PhotoItem>(`/api/v2/photos/${id}/`);
};

const updatePhoto = (id: number, data: UpdatePhotoData): Promise<AxiosResponse<UpdatePhotoItem>> => {
    return httpClient.put<UpdatePhotoItem>(`/api/v2/photos/${id}/`, data);
};

const partialUpdatePhoto = (id: number, data: PartialUpdatePhotoData): Promise<AxiosResponse<UpdatePhotoItem>> => {
    return httpClient.put<UpdatePhotoItem>(`/api/v2/photos/${id}/`, data);
};

const createDownload = (id: number): Promise<AxiosResponse> => {
    return httpClient.get(`/api/v2/photos/${id}/original/`, {
        responseType: 'blob'
    });
};

const getPhotoThumbnail = (id: number, photo_size?: string): Promise<AxiosResponse<PhotoItem>> => {
    return httpClient.get<PhotoItem>(`/api/v2/photos/${id}/thumbnail/`, {
        params: {
            photo_size
        }
    });
};

const bulkUpdatePhoto = (data: BulkUpdatePhotoData): Promise<AxiosResponse<BulkUpdatePhoto>> => {
    return httpClient.put<BulkUpdatePhoto>(`/api/v2/photos/bulk-update/`, data);
};

export default {
    getPhotosList,
    createPhoto,
    getPhotoItem,
    updatePhoto,
    partialUpdatePhoto,
    createDownload,
    getPhotoThumbnail,
    bulkUpdatePhoto
} as PhotosAPIType;
