import Modal from 'component/modal';
import React, { useState } from 'react';

import { parse } from 'query-string';
import { IProps } from './types';
import { IStore } from 'store/reducers/types/reducers';
import { key as keyUser } from 'store/reducers/user/reducer';
import Form, { useRegistry } from 'component/form';
import Input from 'component/form/input';
import { useTranslation } from 'react-i18next';
import InputSelect from 'component/form/input-select';

import style from './style.pcss';
import { useClassnames } from 'hook/use-classnames';
import Button from 'component/button';
import api from 'src/api';
import { useSelector } from 'react-redux';
import { useAlert } from 'component/alert/provider';
import Textarea from 'component/form/textarea';
import { INormalizeObject } from 'component/helper/types/normalize-object';
import { normalizeObject } from 'component/helper/normalize-object';
import InputColor from 'component/form/input-color';
import { CreateAdData } from 'src/api/ads/types';
import { genderOptions, suitSizeOptions } from '../utils';
import Checkbox from 'component/form/checkbox';
import Error from 'component/error';
import InputRange from 'component/form/input-range';

const getNormalizedQuery = () => {
    const qs = parse(location.search);

    return normalizeObject(qs);
};

const SellSuitModal = (props: IProps) => {
    const cn = useClassnames(style);
    const { t } = useTranslation();
    const { form, field } = useRegistry();
    const { show } = useAlert();

    const userPhone = useSelector<IStore, string | undefined>((store) => store[keyUser].fps_phone_number);
    const userEmail = useSelector<IStore, string | undefined>((store) => store[keyUser].email);

    const [queryParams, setQueryParams] = useState<INormalizeObject>(getNormalizedQuery());

    const [error, setError] = useState<boolean>(false);
    const [isColorValid, setIsColorValid] = useState<boolean>(true);
    const [isHeightValid, setIsHeightValid] = useState<boolean>(true);
    const [isAdditionalCheckboxDisabled, setIsAdditionalCheckboxDisabled] = useState<boolean>(true);
    const [isPhoneCheckboxDisabled, setIsPhoneCheckboxDisabled] = useState<boolean>(true);

    const phoneCheckValidity = () => {
        if (userPhone) {
            setError(false);

            return {
                isValid: true,
                error: ''
            };
        }

        const fields = form.getFields();

        if (fields) {
            const phone = fields.get('phone_number');
            const additional_phone = fields.get('additional_phone_number');

            if (phone) {
                if (phone.value.length < 10) {
                    setError(true);

                    return {
                        isValid: false,
                        error: 'Введите корректный номер'
                    };
                }

                setError(false);

                return {
                    isValid: true,
                    error: ''
                };
            }
        }
    };

    const additionalPhoneCheckValidity = () => {
        const fields = form.getFields();

        if (fields) {
            const additional_phone = fields.get('additional_phone_number');

            if (additional_phone) {
                if (additional_phone.value.length < 10 && additional_phone.value.length !== 0) {
                    setError(true);

                    return {
                        isValid: false,
                        error: 'Введите корректный номер'
                    };
                }

                setError(false);

                return {
                    isValid: true,
                    error: ''
                };
            }
        }
    };

    const emailCheckValidity = () => {
        if (userEmail) {
            setError(false);

            return {
                isValid: true,
                error: ''
            };
        }

        const fields = form.getFields();

        if (fields) {
            const email = fields.get('email');

            if (email) {
                if (email.value === '') {
                    setError(true);

                    return {
                        isValid: false,
                        error: 'Введите эл. почту'
                    };
                }

                setError(false);

                return {
                    isValid: true,
                    error: ''
                };
            }
        }
    };

    const onCreateForm = () => {
        if (error) {
            return;
        }

        const formData = form.getPayload();

        if (!formData.height.value_from || !formData.height.value_to) {
            setIsHeightValid(false);

            return;
        }

        setIsHeightValid(true);

        if (formData.color[0] === undefined) {
            setIsColorValid(false);

            return;
        }

        setIsColorValid(true);

        const data: CreateAdData = {
            object_type: 'suit',
            event: queryParams.event_id || props.event,
            person: props.person,
            email: userEmail || formData.email,
            phone_number: userPhone || formData.phone_number,
            ...(formData.additional_phone_number.length && { additional_phone_number: formData.additional_phone_number }),
            suit_size: formData.suit_size.value,
            color: formData.color[0],
            min_height: formData.height.value_from,
            max_height: formData.height.value_to,
            ...(formData.comment.length && { comment: formData.comment }),
            photo: props.photo,
            is_need_help: formData.is_need_help,
            ...(formData.gender.value && { gender: formData.gender.value })
            // extra_args: {
            //     is_hide_name: formData.is_hide_name,
            //     is_hide_number: formData.is_hide_number,
            //     is_hide_additional_number: formData.is_hide_additional_number
            // }
        };

        api.ads.createAd(data)
            .then(() => {
                show('Ваше объявление отправлено на модерацию. Ожидаемый срок проверки до 48 часов.');
                props.onCloseModal();
            })
            .catch((e) => {
                show('Что-то пошло не так.', 'warning');
            });
    };

    const onChangeForm = () => {
        const fields = form.getFields();

        if (fields) {
            const additional_phone = fields.get('additional_phone_number');
            const phone_number = fields.get('phone_number');

            if (additional_phone && additional_phone.value.length > 0) {
                setIsAdditionalCheckboxDisabled(false);
            } else {
                setIsAdditionalCheckboxDisabled(true);
            }

            if (phone_number && phone_number.value.length > 0) {
                setIsPhoneCheckboxDisabled(false);
            } else {
                setIsPhoneCheckboxDisabled(true);
            }
        }
    };

    return (
        <Modal onClickClose={props.onCloseModal}>
            <div>
                <h3>Продажа костюма</h3>
                <Form
                    registry={form}
                    onSubmit={onCreateForm}
                    className={cn('sell-suit__form')}
                    onChange={onChangeForm}
                >
                    <div>
                        <Input
                            registry={field}
                            name="email"
                            children={t('route.persons.sell-suit.email')}
                            direction="column"
                            required={true}
                            defaultValue={userEmail ? userEmail : ''}
                            disabled={!!userEmail}
                            checkValidity={emailCheckValidity}
                        />
                        <Input
                            registry={field}
                            name="phone_number"
                            children={t('route.persons.sell-suit.phone')}
                            direction="column"
                            placeholder="(XXX) XXX-XX-XX"
                            onlyNumbers={true}
                            required={true}
                            defaultValue={userPhone ? userPhone : ''}
                            disabled={!!userPhone}
                            checkValidity={phoneCheckValidity}
                        />
                        {/* <Checkbox
                            registry={field}
                            disabled={isPhoneCheckboxDisabled}
                            name="is_hide_number"
                            children={t('route.persons.sell-suit.show_number')}
                            className={cn('sell-suit__form__checkbox')}
                        /> */}
                        <Input
                            registry={field}
                            name="additional_phone_number"
                            children={t('route.persons.sell-suit.additional_phone')}
                            direction="column"
                            placeholder="(XXX) XXX-XX-XX"
                            onlyNumbers={true}
                            checkValidity={additionalPhoneCheckValidity}
                        />
                        {/* <Checkbox
                            registry={field}
                            disabled={isAdditionalCheckboxDisabled}
                            name="is_hide_additional_number"
                            children={t('route.persons.sell-suit.show_additional_number')}
                            className={cn('sell-suit__form__checkbox')}
                        /> */}
                        <InputSelect
                            registry={field}
                            name="gender"
                            direction="column"
                            children={t('route.persons.sell-suit.gender')}
                            options={genderOptions}
                            defaultValue={genderOptions[0]}
                        />
                        <InputSelect
                            registry={field}
                            name="suit_size"
                            direction="column"
                            children={t('route.persons.sell-suit.suit_size')}
                            options={suitSizeOptions}
                            defaultValue={suitSizeOptions[0]}
                            required={true}
                        />
                        <InputRange
                            registry={field}
                            required={true}
                            minValue={100}
                            maxValue={205}
                            name="height"
                            direction="column"
                            children={t('route.persons.sell-suit.height')}
                        />
                        {!isHeightValid && (
                            <Error elIcon={true} className={cn('color__error')}>Необходимо указать рост</Error>
                        )}
                        <Textarea
                            registry={field}
                            name="comment"
                            children={t('route.persons.sell-suit.comment')}
                            direction="column"
                            maxLength={150}
                        />
                        <InputColor
                            registry={field}
                            name="color"
                            children={t('route.persons.sell-suit.color')}
                            direction="column"
                            required={true}
                            onlyOneSelect={true}
                        />
                        {!isColorValid && (
                            <Error elIcon={true} className={cn('color__error')}>Необходимо выбрать цвет</Error>
                        )}
                        {/* <Checkbox
                            registry={field}
                            name="is_hide_name"
                            children={t('route.persons.sell-suit.show_name')}
                            className={cn('sell-suit__form__checkbox')}
                        /> */}
                        <div className={cn('sell-suit__form__container')}>
                            <Checkbox
                                registry={field}
                                name="is_need_help"
                                children={t('global.form.items.help')}
                                className={cn('sell-suit__form__checkbox')}
                            />
                        </div>
                        <div className={cn('search-partner__submit')}>
                            <Button
                                className={cn('search-partner__action-button')}
                                type="submit"
                            >
                                {t('route.persons.sell-suit.submit')}
                            </Button>
                        </div>
                    </div>
                </Form>
            </div>
        </Modal>
    );
};

export default SellSuitModal;
